<template>
  <div class="row">
    <input-text
      v-model="filtro.ocorrenciaPadraoNome"
      class="col-12 col-md-6"
      :label="$t('modulos.registro_ocorrencia.filtro.ocorrencia_padrao_nome')"
      :placeholder="$t('modulos.registro_ocorrencia.filtro.ocorrencia_padrao_nome')"
      em-filtro
    />
    <input-date
      v-model="filtro.data"
      class="col-12 col-md-6"
      :label="$t('modulos.registro_ocorrencia.filtro.data')"
      :placeholder="$t('modulos.registro_ocorrencia.filtro.data')"
      em-filtro
    />
    <input-text
      v-model="filtro.colaboradorResponsavelNome"
      class="col-12"
      :label="$t('modulos.registro_ocorrencia.filtro.colaborador_responsavel_nome')"
      :placeholder="$t('modulos.registro_ocorrencia.filtro.colaborador_responsavel_nome')"
      em-filtro
    />
  </div>
</template>
<script>
export default {
  props: {
    filtro: {},
  },
  data() {
    return {
    };
  },
};
</script>
