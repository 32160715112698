<template>
  <div>
    <cabecalho-pagina
      :titulo="$t('modulos.registro_ocorrencia.titulos.listagem')"
      :total-registros="tabela.quantidadeItens"
      :titulo-botao-novo="$t('modulos.registro_ocorrencia.titulos.novo')"
      class="mt-2"
      :sem-botao-ok="!buscarPermissao('InstrumentoOcorrencia', 'Inserir')"
      :sem-filtro="!buscarPermissao('InstrumentoOcorrencia', 'Listar')"
      @abrirNovo="abrirNovo"
      @filtrar="filtrar"
    >
      <template v-slot:filtro="{ filtro }">
        <filtro :filtro="filtro" />
      </template>
    </cabecalho-pagina>
    <botao-padrao
      v-if="podeExcluir && buscarPermissao('InstrumentoOcorrencia', 'Deletar')"
      class="my-2"
      outlined
      color="danger"
      @click="excluirRegistros"
    >
      <v-icon>$mdiTrashCanOutline</v-icon>
      {{ $t('geral.botoes.excluir') }}
    </botao-padrao>
    <tabela-padrao
      v-model="tabela.selecionados"
      class="mt-2"
      :mostrar-checkbox="buscarPermissao('InstrumentoOcorrencia', 'Deletar')"
      :dados="tabela.dados"
      :colunas="tabela.colunas"
      :por-pagina="tabela.porPagina"
      :quantidade-paginas="tabela.quantidadePaginas"
      @paginar="listarRegistros"
    >
      <!-- eslint-disable vue/valid-v-slot-->
      <template v-slot:item.acoes="{ item }">
        <dropdown-padrao
          v-if="buscarPermissao('InstrumentoOcorrencia', 'Editar')"
          text
          color="secondary"
        >
          <template #botao>
            <v-icon> $dotsVertical </v-icon>
          </template>
          <div>
            <dropdown-padrao-item
              text
              color="secondary"
              @click="abrirEditar(item.item)"
            >
              {{ $t('geral.botoes.editar') }}
            </dropdown-padrao-item>
          </div>
        </dropdown-padrao>
      </template>
    </tabela-padrao>
  </div>
</template>
<script>
import helpers from '@common/utils/helpers';
import CabecalhoPagina from '@components/layout/CabecalhoPagina.vue';
import RegistroOcorrenciaService from '@common/services/cadastros/RegistroOcorrenciaService';
import Filtro from './filtro.vue';
export default {
  components: {
    CabecalhoPagina,
    Filtro,
  },
  props: {
    idInstrumento: { type: String, default: '' },
    atualizaListagem: { type: Number, default: 1 },
    instrumentoOcorrencia: { type: Object, default: () => ({}) },
  },
  data() {
    return {
      tabela: {
        selecionados: [],
        dados: [],
        colunas: [
          {
            value: 'acoes',
            text: '',
          },
          {
            value: 'ocorrenciaPadrao.nome',
            text: this.$t(
              'modulos.registro_ocorrencia.tabela.ocorrencia_padrao'
            ),
            sortable: true,
          },
          {
            value: 'colaboradorResponsavel.nome',
            text: this.$t(
              'modulos.registro_ocorrencia.tabela.colaborador_responsavel'
            ),
            sortable: true,
          },
          {
            value: 'data',
            text: this.$t('modulos.registro_ocorrencia.tabela.data'),
            sortable: true,
            formatter: (v) => {
              if (v) return helpers.formatarDataBr(v, false);
            },
          },
        ],
        quantidadeItens: 0,
        quantidadePaginas: 0,
        paginaAtual: 0,
        porPagina: 10,
      },
      filtroAplicado: { filter: '' },
    };
  },
  computed: {
    podeExcluir: function () {
      return this.tabela.selecionados.length > 0;
    },
  },
  watch: {
    atualizaListagem() {
      this.listarRegistros();
    },
  },
  mounted() {
    this.listarRegistros();
  },
  created() {
    helpers.redirecionarSemPermissao(this, 'InstrumentoOcorrencia', 'Listar');
  },
  methods: {
    buscarPermissao(funcionalidade, funcionalidadeAcao) {
      return helpers.buscarStatePermissoes(
        this,
        funcionalidade,
        funcionalidadeAcao
      );
    },
    filtrar: function (filtro) {
      this.filtroAplicado = { ...filtro, filter: this.filtroAplicado.filter };
      this.listarRegistros();
    },
    listarRegistros: function (paginaAtual = 1, porPagina = 10) {
      let parametros = {
        page: paginaAtual,
        amountPerPage: porPagina,
        instrumentoId: this.idInstrumento,
        ...this.filtroAplicado,
      };
      this.$store.dispatch('Layout/iniciarCarregamento');
      RegistroOcorrenciaService.listar(parametros)
        .then((res) => {
          this.tabela.dados = res.data.items;
          this.tabela.quantidadeItens = res.data.totalItems;
          this.tabela.quantidadePaginas = res.data.totalPages;
          this.tabela.paginaAtual = paginaAtual;
          this.tabela.porPagina = porPagina;
        })
        .finally(() => {
          this.$store.dispatch('Layout/terminarCarregamento');
        });
    },
    excluirRegistros: async function () {
      this.confirmarExcluir().then(() => {
        this.$store.dispatch('Layout/iniciarCarregamento');
        let promises = [];
        this.tabela.selecionados.forEach((registro) => {
          promises.push(RegistroOcorrenciaService.excluir(registro.id));
        });

        Promise.all(promises)
          .then(() => {
            this.listarRegistros(
              this.tabela.paginaAtual,
              this.tabela.porPagina
            );
            this.toastSucesso(
              this.$t(`modulos.registro_ocorrencia.exclusao_sucesso`)
            );
            this.tabela.selecionados = [];
          })
          .finally(() => {
            this.$store.dispatch('Layout/terminarCarregamento');
          });
      });
    },
    abrirNovo: function () {
      this.$emit('troca-aba');
    },
    abrirEditar: function (item) {
      let id = item.id;
      this.$emit('troca-aba', id);
    },
  },
};
</script>
